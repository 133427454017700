<template>
  <v-data-table
    :headers="headers"
    :items="filteredItems"
    :loading="loading"
    hide-default-footer
    :items-per-page="10000"
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="(item, key) in items" :key="key">
          <td>
            <router-link
              :to="{ name: 'influencer', params: { id: item.influencer.uuid } }"
            >
              {{ item.influencer.user.first_name }}
              {{ item.influencer.user.last_name }}
            </router-link>
          </td>
          <td class="text-right">{{ item.clicks }}</td>
          <td class="text-right">{{ item.sales }}</td>
          <td class="text-right">{{ item.conversion | local_numbers }}%</td>
          <td class="text-right">{{ item.value | local_numbers }}</td>
          <td class="text-right">{{ item.commission | local_numbers }}</td>
        </tr>
      </tbody>
    </template>

    <div class="text-center" slot="no-data">
      No data found.
    </div>
  </v-data-table>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [
      {
        text: "Influencer",
        value: "influencer.user.first_name",
        sortable: true
      },
      { text: "Clicks", value: "clicks", align: "right", sortable: true },
      { text: "Sales", value: "sales", align: "right", sortable: true },
      {
        text: "Conversion",
        value: "conversion",
        align: "right",
        sortable: true
      },
      { text: "Revenue", value: "value", align: "right", sortable: true },
      {
        text: "Commission",
        value: "commission",
        align: "right",
        sortable: true
      }
    ]
  }),
  methods: {
    goToCampaign(campaign) {
      this.$router.push({ name: "campaign", params: { id: campaign.uuid } });
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.influencer !== null);
    }
  }
};
</script>
