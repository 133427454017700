<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Influencer report</div>
          <span class="subtitle-1 light-grey"
            >Overview of your influencers' accumulated performance</span
          >
        </div>
        <v-spacer></v-spacer>

        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                  filtersTmp = cloneDeep(filters);
                  filterDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <influencer-stats-data-table
          :loading="loading"
          :items="stats"
        ></influencer-stats-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter report
        </v-card-title>
        <v-card-text>
          <business-select
            v-model="filtersTmp.business_uuid"
            :return-object="false"
          ></business-select>
          <campaign-autocomplete
            v-model="filtersTmp.campaign_uuid"
            :return-object="false"
            :items="campaigns"
            :businessId="filtersTmp.business_uuid"
          ></campaign-autocomplete>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Period start"
            cancel-button
            :reset-button="false"
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="Period end"
            cancel-button
            :reset-button="false"
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              filterDialog = false;
            "
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InfluencerStatsDataTable from "../../../data-tables/InfluencerStatsDataTable";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import DatePicker from "@/components/common/filters/DatePicker";
import BusinessSelect from "../../../selects/BusinessSelect";
import CampaignAutocomplete from "../../../autocompletes/CampaignAutocomplete";
import { mapActions } from "vuex";

export default {
  components: {
    CampaignAutocomplete,
    BusinessSelect,
    DatePicker,
    InfluencerStatsDataTable
  },
  mixins: [search_filters_url],
  data: () => ({
    loading: true,
    stats: [],
    filterDialog: false,
    filtersTmp: {},
    filters: {},
    campaigns: []
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.getAllStats();
      },
      deep: true
    },
    "filtersTmp.business_uuid": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.filtersTmp.campaign_uuid = null;
          this.getCampaigns(newValue);
        }
      }
    }
  },
  methods: {
    ...mapActions("core/campaigns", ["loadCampaigns"]),
    ...mapActions("core", ["getStats"]),
    getAllStats() {
      this.loading = true;
      let params = this.cloneDeep(this.filters);
      params.group = ["influencer"];
      if (params.campaign_uuid === null) {
        delete params["campaign_uuid"];
      }
      this.getStats(params).then(
        stats => {
          this.loading = false;
          this.stats = stats;
        },
        () => {
          this.loading = false;
          this.setSnackError("Couldn't fetch data");
        }
      );
    },
    getCampaigns() {
      const params = {
        page: 1,
        limit: 100,
        active_campaigns: true
      };
      // Get all active campaigns
      this.loadCampaigns(params).then(
        response => {
          this.campaigns = response.response;
        },
        () => {
          this.setSnackError("Error");
        }
      );
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    this.getCampaigns();
    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment()
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      this.getAllStats();
    }
  }
};
</script>
